<!-- 远程请求 -->
<template>
  <div
    class="main_RemoteGuidance"
    v-loading="loading"
    element-loading-text="正在请求老师视频指导"
  >
    <div class="collecticon_lj" @click="ringOff()">
      <div class="gd" v-if="connect_flag">
        <div class="gd_img">
          <img src="@/assets/phonegd.png" />
        </div>
        <div class="gd_name">
          <span>挂断</span>
        </div>
      </div>
      <div v-else>
        <img src="@/assets/phone@2x.png" class="qd"/>
      </div>
    </div>
    <div class="collecticon_sc">
      <div class="small" v-show="video_flag">
        <video
          ref="smallScreen"
          autoplay
          controls
          vjs-fluid
          src=""
          class="video-js vjs-default-skin vjs-big-play-centered"
          style="height: 100%; width: 100%;"
        ></video>
      </div>
      <video
        ref="largeScreen"
        autoplay
        controls
        vjs-fluid
        src=""
        class="video-js vjs-default-skin vjs-big-play-centered"
        style="height: 100%; width: 100%;"
      ></video>
    </div>
  </div>
</template>
<script>
import videojs from "video.js";
import "videojs-contrib-hls";
import { remoteGetList, set_remote } from "@/api/vr";
export default {
  name: "RemoteGuidance",
  components: {},
  inject:['reload'],
  data() {
    return {
      loading: true, // 加载中
      video_flag: false, // 屏幕
      connect_flag: false, // 远程
      small_screen: null, // 小屏
      large_screen: null, // 大屏
      waiter: null, // 延时
      timer: null,
    };
  },
  watch: {
    small_screen(val) {
      console.log("监听小屏变化", val);
      if (val) {
        this.video_flag = true; // 显示小屏
        this.turnOn("smallScreen", val); // 打开小屏
      } else {
        console.log("链接失败，重新请求中...");
      }
    },
    large_screen(val) {
      console.log("监听小屏变化", val);
      if (val) {
        this.loading = false; // 关闭加载
        this.connect_flag = true; // 接通远程
        this.turnOn("largeScreen", val); // 打开大屏
      } else {
        console.log("链接失败，重新请求中...");
      }
    },
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    lab_room_table_id() {
      return Number(this.$route.query.lab_room_table_id);
    },
    request_side_src() {
      return this.$route.query.request_side_src;
    },
  },
  created() {},
  mounted() {
    /* 呼叫 */
    let data = {
      action: 1,
      lab_room_table_id: this.lab_room_table_id
    }
    this.setRemote(data); // 设置远程连接
    /* 获取数据 */
    this.remoteGetList(); // 获取远程数据
    this.timer = setInterval(() => {
      this.remoteGetList(); // 获取远程数据
    }, 5000);
    //销毁定时器
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.waiter);
      clearInterval(this.timer);
    });
    /* 打开视屏 */
    this.$nextTick(() => {
      console.log("初始化", this.small_screen);
    });
  },
  methods: {
    /* 获取远程数据 */
    async remoteGetList(params) {
      try {
        let response = await remoteGetList(params);
        console.log("获取远程数据", response);
        let _self = this
        if (response.code == 0) {
          let resData = response.data;
          if (resData === null) { //连接失败返回
            // this.setRemote(0); // 离线
            _self.connect_flag = false;
            // 跳转
            _self.$router.push({
              path:
                "/home/virtualsimulationexperiment/experimentdetails?id=" + _self.id,
            });
          } else {
            clearInterval(_self.timer); //连接成功后清除定时器请求
            _self.small_screen = resData?.interplay_url??''; //小屏
            _self.large_screen =  resData?.send_url??''; //大屏
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /* 打开屏幕 */
    turnOn(screen, url) {
      this.singlePlayer = videojs(this.$refs[screen], {
        autoplay: true, // 自动播放
        controls: false, // 控制栏
        // muted: true, // 静音
        fluid: true, // 自适应
        preload: "metadata", // 预加载
      });
      if (url) {
        this.singlePlayer.src({ src: url, type: "application/x-mpegURL" });
        this.singlePlayer.play();
      }
    },
    /* 发送远程连接 */
    async setRemote(val) {
      let params = {
        action: val.action,
        lab_room_table_id: val.lab_room_table_id,
      };
      try {
        let response = await set_remote(params);
        // console.log("远程连接",response);
        if (response.code === 0) {
          console.log("连接成功！！！");
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 挂断
    ringOff() {
      clearInterval(this.timer)
      this.loading = true;
      this.reload()
      let data = {
        action: 0,
        lab_room_table_id: this.lab_room_table_id
      }
      this.setRemote(data); // 离线
      this.connect_flag = false;
      // 跳转
      this.$router.push({
        path: "/home/virtualsimulationexperiment/experimentdetails?id=" + this.id,
      });
      this.loading = false;
    },
  },
  beforeDestroy() {
    this.singlePlayer.dispose()
    clearInterval(this.timer)
  }
};
</script>
<style scoped lang="scss">
.main_RemoteGuidance {
  width: 100%;
  height: 1110px;
  background: #dedede;
  .collecticon_lj {
    position: absolute;
    z-index: 999999;
    bottom: 16%;
    left: 50%;
    transform: translateX(-50px);
    .gd {
      width: 100px;
      height: 40px;
      line-height: 40px;
      background: red;
      color: #fff;
      text-align: center;
      font-size: 14px;
      border-radius: 5px;
      cursor: pointer;
      .gd_img {
        width: 50%;
        float: left;
        text-align: center;

        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
        }
      }
      .gd_name {
        width: 50%;
        float: left;
        text-align: left;
      }
    }
    .qd {
      width: 60px;
      height: 60px;
      background: #fff;
      border-radius: 50%;
    }
  }
  .collecticon_sc {
    position: relative;
    width: 100%;
    height: 100%;
    .small {
      position: absolute;
      z-index: 20;
      right: 20px;
      top: 20px;
      width: 400px;
      height: 400px;
      border: 1px solid #fff;
    }
  }
}
</style>